define([
    "angular",
    "./filterModule",
    "lodash",
    "../arrays/arrays",
    "../objects/objects",
    "../jsonpatches/jsonpatches",
    "text!./nlgFilterEdit.html",
    "text!./nlgFilterSave.html"
], function (angular, filterModule, _, arrays, objects, jsonpatches, nlgFilterEdit, nlgFilterSave) {
    "use strict";

    filterModule.controller("filterController", ["$scope", "$modal", "filterServiceFactory", "$q", "$attrs", "$translate", "remoteExceptionHandler", "filterState", "loadingService", "filterDomain", "$log", "sortingListModal", "messagesModal", function ($scope, $modal, filterServiceFactory, $q, $attrs, $translate, remoteExceptionHandler, filterState, loadingService, filterDomain, $log, sortingListModal, messagesModal) {
        var filterSort, originalFilterSort, defaultFilterSort;
        var template;
        var selectedFilters = [];
        var originalFilterFields = [];
        var combinationFieldsNames = [];
        var combinationSnapthotsNames = [];
        var filterGroups;
        var api = {};
        var savedState = null;
        var filterService = $scope.filterService || filterServiceFactory($scope.domain || filterDomain.getDefaultDomain());
        var selectedFilterSnapshotName = null;
        var selectedFilterSnapshotIsDefault = false;
        var customizedFilter = false;
        var delayIncompatibleFilters = ["transitions.estimatedTimestamp", "transitions.limitTimestamp", "transitions.status"];
        var extensionKeyField = ".extension.key";
        var extensionField = ".extension.";
        $scope.uiControl = {
            templateLoaded: false,
            hasFilter: false,
            loading: false,
            isSortable: false,
            isCombineButtonDisabled: true
        };
        $scope.operationDescriptions = null;
        $scope.showOperator = $scope.showOperator !== undefined ? $scope.showOperator : true;

        if (angular.isUndefined($scope.translateStringTags)) {
            $scope.translateStringTags = true;
        }

        $scope.openSortModal = function () {
            sortingListModal(filterSort)
                .then(function (response) {
                    filterSort = response;
                    savedState.sortingActivated = filterSort.sortingActivated;
                    savedState.sortableFields = filterSort.sortableFields;
                });
        };

        var changeServiceCallbacks = [];
        api.onChangeService = function (callback) {
            changeServiceCallbacks.push(callback);
        };
        $scope.$watch(function () {
            return $attrs.service;
        }, function (newServiceName) {
            $scope.serviceName = newServiceName;
            savedState = filterState.for(newServiceName);
            if (newServiceName) {
                loadingService(filterService.getFilter(newServiceName).success(function (filter) {
                    originalFilterSort = angular.copy(filterSort = filter || {});
                    defaultFilterSort = filter.sortableFields;
                    filterSort.sortableFields = savedState.sortableFields || filterSort.sortableFields || defaultFilterSort;
                    filterSort.sortingActivated = savedState.sortingActivated || filterSort.sortingActivated;
                    template = filter.template;
                    $scope.uiControl.templateLoaded = true;
                    $scope.uiControl.hasFilter = hasFilter(template);
                    $scope.uiControl.isSortable = filter.sortableFields.length;
                    if (savedState.patch && savedState.patch.length) {
                        $scope.setSelectedFilters(savedState.patch);
                        setSelectedFilterSnapshot(savedState.name, savedState.defaultFilter);
                        customizedFilter = savedState.customizedFilter;
                    } else {
                        if ($scope.preSelectedFilters) {
                            $scope.setSelectedFilters($scope.preSelectedFilters);
                        } else {
                            $scope.loadDefaultFilterIfExists()
                                .success(function (snapshot) {
                                    if (snapshot) {
                                        $scope.selectFilterSnapshot(snapshot);
                                        $scope.convertAndSetCombinationOriginalFilter(snapshot);
                                    } else {
                                        // cópia para não alterar o estado inicial, para geração de patch
                                        var defaultedTemplate = populateDefaultOperationsAndValues(angular.copy(template));
                                        $scope.setSelectedFilters(objects.values(getInitialFields(defaultedTemplate)));
                                    }
                                });
                        }
                    }
                    $scope.loadFilterSnapshots();
                    notifyListeners();
                }));
            } else {
                $scope.setSelectedFilters([]);
                notifyListeners();
            }

            function notifyListeners() {
                arrays.each(changeServiceCallbacks, function (callback) {
                    callback(newServiceName);
                });
            }

            function hasFilter(template) {
                return Object.keys(template).length > 0;
            }
        });

        $scope.deleteFilterButtons = false;
        $scope.$watch(function () {
            return selectedFilters.length;
        }, function () {
            $scope.deleteFilterButtons = selectedFilters.length !== 0;
        });

        filterService.getFilterOperationDescriptions().success(function (operationDescriptions) {
            $scope.operationDescriptions = operationDescriptions;
        });

        $scope.getOperation = function (filterOperation) {
            var result = false;
            arrays.each($scope.operationDescriptions, function (value, key) {
                if (filterOperation === key) {
                    result = value.name;
                    return arrays.each.BREAK;
                }
            });
            return result;
        };

        //Assume-se que ponto seguido de espaços em branco formam uma frase.
        $scope.getFilterName = function (filterField) {
            if (/\.\s/.test(filterField.fieldName)) {
                return filterField.fieldName;
            } else {
                return translate("entityCrudView.filter.field." + filterField.fieldName.split(".").pop());
            }
        };

        $scope.getGroupName = function (group) {
            return translate("entityCrudView.filter.inner." + group);
        };

        function translate(string) {
            return $translate.instant(string)
                .replace(/^entityCrudView\.filter\.field\./, "")
                .replace(/^entityCrudView\.filter\.inner\./, "")
                .replace(/_/g, " ");
        }

        $scope.getFilterGroups = function () {
            return filterGroups;
        };

        $scope.removeFilter = function (filter) {
            var extensionOwner = filter.fieldName.split(".")[0];
            if (filter.fieldName.indexOf(extensionKeyField) !== -1) {
                var extraExtensionValueFilter = selectedFilters.filter(function (selectedFilter) {
                    return selectedFilter.fieldName.startsWith(extensionOwner + extensionField) && selectedFilter.fieldName !== extensionOwner + extensionKeyField;
                });
                if (extraExtensionValueFilter.size > 0) {
                    arrays.remove(selectedFilters, extraExtensionValueFilter[0]);
                }
            }
            arrays.remove(selectedFilters, filter);
            $scope.setSelectedFilters(selectedFilters);
        };

        $scope.deleteFilterShowButtons = function () {
            var requiredFilters = [];
            arrays.each(selectedFilters, function (filter) {
                if (filter.required) {
                    requiredFilters.push(filter);
                }
            });
            selectedFilterSnapshotName = "";
            combinationSnapthotsNames = [];
            customizedFilter = false;
            $scope.setSelectedFilters(requiredFilters);
        };

        $scope.getSelectedFilters = function () {
            return selectedFilters;
        };

        function reduceNames(filterValue) {
            return filterValue.fieldName;
        }

        function shouldRemoveFilter(filterValue) {
            return !delayIncompatibleFilters.includes(filterValue.fieldName);
        }

        $scope.setSelectedFilters = function (newValue) {
            var newValueFieldNames = newValue.map(reduceNames);
            var isDelaySelected = newValueFieldNames.indexOf("delayinexecution") !== -1;

            if (isDelaySelected && $scope.serviceName.indexOf("monitorable") !== -1) {
                if (_.intersection(newValueFieldNames, delayIncompatibleFilters).length > 0) {
                    newValue = newValue.filter(shouldRemoveFilter);
                    messagesModal("dialog.warning", ["monitoring.filter.incompatible:", delayIncompatibleFilters[0],
                        delayIncompatibleFilters[1], delayIncompatibleFilters[2]]);
                }
            }

            selectedFilters = newValue;
            combinationFieldsNames = [];
            arrays.each(newValue, function (value) {
                combinationFieldsNames.push(value.fieldName);
            });

            var groupObject = {};
            arrays.each(selectedFilters, function (filter) {
                var key = (filter.fieldName.match(/^([\s\S]*)\.\w+$/) || [])[1] || "";
                groupObject[key] = (groupObject[key] || []).concat([filter]);
            });
            filterGroups = arrays.map(groupObject, function (selection, groupName) {
                return {
                    name: groupName,
                    selectedFilters: selection
                };
            }).sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });

            savedState.patch = selectedFilters;
            filterState.update($scope.serviceName, savedState);
            checkCombineButtonIsDisabled();
        };

        $scope.search = function () {
            savedState.filtered = true;
            var templateAndPatch = generatePatches();
            $scope.uiControl.loading = true;
            $q.when($scope.filterAction({
                filters: templateAndPatch.selection,
                modifiedTemplate: templateAndPatch.modifiedTemplateParameter,
                originalTemplate: templateAndPatch.originalTemplateParameter,
                patch: templateAndPatch.patch
            }))
                .catch(remoteExceptionHandler())
                .finally(function () {
                    $scope.uiControl.loading = false;
                });
        };

        function generatePatches() {
            var modifiedTemplate = angular.copy(template);
            var selection = arrays.filter(selectedFilters, function (filter) {
                return isValidValue(filter.value) || isValidMultipleValue(filter.multipleValue);
            });
            arrays.each(selection, function (filter) {
                modifiedTemplate[filter.fieldName] = filter;
            });
            var modifiedTemplateParameter = angular.copy({
                sortableFields: filterSort.sortableFields || defaultFilterSort,
                sortingActivated: filterSort.sortingActivated || false,
                template: modifiedTemplate
            });
            var originalTemplateParameter = angular.copy({
                sortableFields: originalFilterSort.sortableFields || defaultFilterSort,
                sortingActivated: originalFilterSort.sortingActivated || false,
                template: template
            });
            return {
                selection: selection,
                modifiedTemplateParameter: modifiedTemplateParameter,
                originalTemplateParameter: originalTemplateParameter,
                patch: jsonpatches.compare(originalTemplateParameter, modifiedTemplateParameter)
            };
        }

        var isValidValue = function (filterValue) {
            return angular.isDefined(filterValue) && filterValue !== null;
        };

        var isValidMultipleValue = function (filterMultipleValue) {
            return arrays.isArray(filterMultipleValue) && filterMultipleValue.length && filterMultipleValue[0] !== null && filterMultipleValue[1] !== null;
        };

        api.search = $scope.search;
        api.reset = function () {
            savedState.filtered = false;
        };
        api.reload = function () {
            if (savedState.filtered) {
                api.search();
            }
        };

        $scope.editFilterConfiguration = function () {
            $modal.open({
                size: "lg",
                resolve: {
                    selectedFilters: supplier(selectedFilters),
                    originalTemplate: supplier(template)
                },
                template: nlgFilterEdit,
                controller: "nlgFilterEdit"
            }).result.then(function (newSelectedFilters) {
                customizedFilter = true;
                savedState.customizedFilter = true;
                $scope.setSelectedFilters(newSelectedFilters);
            });
        };

        $scope.getAvailableOperations = function (filterField) {
            return arrays.filter(filterField.availableOperations || filterField.filterType.defaultOperations, notSelected);

            function notSelected(operation) {
                return filterField.operation !== operation;
            }
        };

        $scope.saveFilterConfiguration = function (filterForm) {
            if (!filterForm.$valid) {
                return messagesModal("dialog.warning", ["entityCrudView.filter.invalid.filter"]);
            }
            $modal.open({
                template: nlgFilterSave,
                controller: "nlgFilterSave",
                resolve: {
                    filterSnapshots: function () {
                        return $scope.filterSnapshots;
                    },
                    selectedFilterSnapshotName: function () {
                        return selectedFilterSnapshotName;
                    },
                    defaultFilter: function () {
                        return selectedFilterSnapshotIsDefault;
                    }
                }
            }).result.then(function (result) {
                filterService
                    .saveFilterSnapshot($scope.serviceName, result.filterName, result.defaultFilter, angular.toJson(selectedFilters), filterSort)
                    .then(function () {
                        setSelectedFilterSnapshot(result.filterName, result.defaultFilter);
                        $scope.loadFilterSnapshots();
                    })
                    .catch(setErrorMessage);
            });
        };

        $scope.loadFilterSnapshots = function () {
            filterService.getFilterSnapshots($scope.serviceName)
                .success(function (snapshots) {
                    $scope.filterSnapshots = snapshots;
                    checkCombineButtonIsDisabled();
                })
                .error(setErrorMessage);
        };

        function checkCombineButtonIsDisabled() {
            var hasMoreThanOneSnapshot = false;
            var requiredFilters = arrays.filter($scope.getSelectedFilters(), function (filter) {
                return filter.required === true || filter.operation;
            });

            if ($scope.filterSnapshots) {
                var totalFilterSnapshots = $scope.filterSnapshots.length;
                if (totalFilterSnapshots === 1) {
                    if (requiredFilters.length && (selectedFilterSnapshotName !== $scope.filterSnapshots[0].name)) {
                        hasMoreThanOneSnapshot = true;
                    }
                } else {
                    if (!selectedFilterSnapshotName && !requiredFilters.length) {
                        hasMoreThanOneSnapshot = false;
                    } else {
                        hasMoreThanOneSnapshot = totalFilterSnapshots > 1;
                    }
                }
            }
            $scope.uiControl.isCombineButtonDisabled = !hasMoreThanOneSnapshot || customizedFilter;
        }

        // Seleção de filtro
        $scope.selectFilterSnapshot = function (filterSnapshot) {
            savedState.name = filterSnapshot.name;
            savedState.defaultFilter = filterSnapshot.defaultFilter;
            if (!filterSnapshot.sortableFields.length && !savedState.sortablesFields) {
                filterSnapshot.sortableFields = defaultFilterSort;
            }
            filterSort.sortableFields = savedState.sortableFields = filterSnapshot.sortableFields;
            filterSort.sortingActivated = savedState.sortingActivated = filterSnapshot.sortingActivated;
            setSelectedFilterSnapshot(filterSnapshot.name, filterSnapshot.defaultFilter);
            $scope.setSelectedFilters(buildFilters(filterSnapshot.patch));
        };

        // Combinar filtros
        $scope.combineFilterSnapshots = function (filterSnapshot) {
            var selectedFilters = angular.copy($scope.getSelectedFilters());

            //sobrepõe filtros iguais
            arrays.each(buildFilters(filterSnapshot.patch), function (newField) {
                var fieldToReplace = arrays.filter($scope.getSelectedFilters(), function (selectedFilter) {
                    return selectedFilter.fieldName === newField.fieldName;
                });
                if (fieldToReplace[0]) {
                    var index = arrays.indexOf(selectedFilters, fieldToReplace[0]);
                    arrays.insertAt(selectedFilters, index, newField);
                    arrays.remove(selectedFilters, fieldToReplace[0]);
                } else {
                    selectedFilters.push(newField);
                }
            });

            if (!arrays.contains(combinationSnapthotsNames, filterSnapshot.name)) {
                combinationSnapthotsNames.push(filterSnapshot.name);
            }
            $scope.setSelectedFilters(selectedFilters);
        };

        // Deletar configuração de filtros
        $scope.deleteFilterSnapshot = function (filterSnapshot) {
            filterService.deleteFilterSnapshot($scope.serviceName, filterSnapshot.id)
                .catch(remoteExceptionHandler())
                .finally(function () {
                    if ($scope.isFilterSnapshotSelected(filterSnapshot)) {
                        selectedFilterSnapshotName = "";
                        selectedFilterSnapshotIsDefault = false;
                    }
                    $scope.loadFilterSnapshots();
                });
        };

        // Remover configuração combinada
        $scope.removeFilterSnapshotOfCombination = function (filterSnapshot) {
            var selectedFilters = angular.copy($scope.getSelectedFilters());

            arrays.each(buildFilters(filterSnapshot.patch), function (filterToRemove) {
                if (arrays.contains(selectedFilters, filterToRemove) && !filterToRemove.required) {
                    var originalFilter = arrays.filter(originalFilterFields, function (originalFilter) {
                        return filterToRemove.fieldName === originalFilter.fieldName;
                    });
                    if (originalFilter[0]) {
                        var index = arrays.indexOf(selectedFilters, filterToRemove);
                        arrays.insertAt(selectedFilters, index, originalFilter[0]);
                    }
                    arrays.remove(selectedFilters, filterToRemove);
                }
            });
            if (arrays.contains(combinationSnapthotsNames, filterSnapshot.name)) {
                arrays.remove(combinationSnapthotsNames, filterSnapshot.name);
            }
            $scope.setSelectedFilters(selectedFilters);
        };

        $scope.loadDefaultFilterIfExists = function () {
            return filterService.getDefaultFilterSnapshot($scope.serviceName)
                .error(setErrorMessage);
        };

        var clearFilter = function (filter) {
            filter.value = null;
            filter.multipleValue = [];
        };

        $scope.clearFilter = function () {
            arrays.each(selectedFilters, clearFilter);
        };

        $scope.loadPossibleValues = function (filter, viewValue) {
            if (filter.possibleValues) {
                return $q.when(filter.possibleValues);
            }
            if (angular.isString(viewValue) && filter.possibleValuesLink) {
                if (filter.domain) {
                    return getPossibleValues(filterServiceFactory(filter.domain), filter, viewValue);
                }
                return getPossibleValues(filterService, filter, viewValue);
            }
            return $q.when([]);

            function getPossibleValues(filterServiceForDomain, filter, viewValue) {
                return filterServiceForDomain.getPossibleValues(filter.possibleValuesLink, getFilterContext(filter), viewValue)
                    .then(function (data) {
                        return data.data;
                    });
            }

            function getFilterContext(filter) {
                var result = {};
                if (filter.dependencies) {
                    var textDependencies = [];
                    var dependencies = [];
                    arrays.each(selectedFilters, function (selectedFilter) {
                        if (arrays.contains(filter.dependencies, selectedFilter.fieldName)) {
                            if (selectedFilter.value) {
                                if (typeof selectedFilter.value === "string") {
                                    textDependencies.push(selectedFilter.value);
                                } else {
                                    dependencies.push(selectedFilter.value);
                                }
                            } else if (selectedFilter.multipleValue && selectedFilter.multipleValue.length > 0) {
                                arrays.each(selectedFilter.multipleValue, function (value) {
                                    if (typeof value === "string") {
                                        textDependencies.push(value);
                                    } else {
                                        dependencies.push(value);
                                    }
                                });
                            }
                        }
                    });
                    result.dependencies = dependencies;
                    result.textDependencies = textDependencies;
                }
                result.additionalInfo = filter.additionalInfo;
                return result;
            }
        };

        if ($scope.onRegisterApi) {
            $scope.onRegisterApi({
                api: api
            });
        }

        function setErrorMessage(err) {
            $log.error(err);
        }

        function setSelectedFilterSnapshot(filterSnapshotName, isDefault) {
            selectedFilterSnapshotName = filterSnapshotName;
            selectedFilterSnapshotIsDefault = isDefault;
            customizedFilter = false;
            combinationSnapthotsNames = [];
            combinationSnapthotsNames.push(filterSnapshotName);
        }

        $scope.isFilterSnapshotSelected = function (filterSnapshot) {
            return selectedFilterSnapshotName === filterSnapshot.name;
        };

        $scope.isFilterInCombination = function (filterSnapshot) {//TODO
            var diffence = arrays.minus(buildFilters(filterSnapshot.patch), $scope.getSelectedFilters());
            return !diffence.length;
        };

        function supplier(value) {
            return function () {
                return value;
            };
        }

        function populateDefaultOperationsAndValues(filterTemplate) {
            objects.values(filterTemplate).forEach(function (filter) {
                if (filter.defaultOperation) {
                    filter.operation = filter.defaultOperation;
                }
                if (filter.defaultFilterValue) {
                    filter.value = filter.defaultFilterValue;
                }
                if (filter.defaultMultipleValue) {
                    filter.multipleValue = filter.defaultMultipleValue;
                }
            });
            return filterTemplate;
        }

        function getInitialFields(filterFieldArray) {
            if (savedState.hasValues()) {
                return savedState.patch;
            }
            var copiedTemplate = angular.copy(filterFieldArray);
            var requiredFields = arrays.filter(copiedTemplate, function (filterField) {
                if (filterField.required === true || filterField.operation) {
                    originalFilterFields.push(filterField);
                    return filterField;
                }
            });
            if (Object.keys(requiredFields).length === 0) {
                return [];
            }
            arrays.each(requiredFields, function (field) {
                if (!field.operation) {
                    field.operation = $scope.getAvailableOperations(field)[0];
                }
            });
            return requiredFields;
        }

        $scope.alphabeticalOrder = function (item) {
            return $translate.instant(item);
        };

        $scope.convertAndSetCombinationOriginalFilter = function (filterSnapshot) {
            originalFilterFields = buildFilters(filterSnapshot.patch);
        };

        $scope.executeExtraAction = function (action) {
            action.execute($scope.serviceName, generatePatches());
        };

        function buildFilters(patch) {
            var filters = [];
            arrays.each(angular.fromJson(patch), function (filter) {
                var newFilter = angular.copy(template[filter.fieldName]);
                newFilter.operation = filter.operation;
                newFilter.multipleValue = filter.multipleValue;
                newFilter.value = filter.value;
                filters.push(newFilter);
            });
            return filters;
        }

        $scope.changeFilterOperator = function (filter, operator) {
            if (filter.operation !== operator) {
                filter.operation = operator;
                clearFilter(filter);
            }
        };

        $scope.showSearchButton = function () {
            return !$attrs.hasOwnProperty("hideSearchButton");
        };

        $scope.showSortButton = function () {
            return !$attrs.hasOwnProperty("hideSortButton");
        };

        $scope.createAdditionalFilterForExtension = function (field) {
            var extensionOwner = field.fieldName.split(".")[0];
            if (field.fieldName.indexOf(extensionKeyField) !== -1) {
                resetAdditionalExtensionFilters(extensionOwner + extensionField);
                addTypedValueFilter(field, extensionOwner + extensionField);
                if (field.value) {
                    field.value = field.value.key;
                }
            }
        };

        function resetAdditionalExtensionFilters(prefixFilter) {
            var extensionValueFiltersToBeRemoved = selectedFilters.filter(function (filter) {
                return filter.fieldName.startsWith(prefixFilter) && filter.fieldName !== prefixFilter + "key";
            });

            extensionValueFiltersToBeRemoved.forEach(function (filter) {
                $scope.removeFilter(filter);
            });
        }

        function addTypedValueFilter(extension, fieldName) {
            if (extension.value && extension.value.textValue) {
                addExtensionValueFilter(fieldName + "textValue", "text");
            }
            if (extension.value && extension.value.integerValue) {
                addExtensionValueFilter(fieldName + "integerValue", "number");
            }
            if (extension.value && extension.value.floatValue) {
                addExtensionValueFilter(fieldName + "floatValue", "number");
            }
            if (extension.value && extension.value.instantValue) {
                addExtensionValueFilter(fieldName + "instantValue", "date");
            }
            if (extension.value && extension.value.booleanValue) {
                addExtensionValueFilter(fieldName + "booleanValue", "boolean");
            }
        }

        function getOperationsFromType(typeName) {
            var possibleValues = [];
            var webFilterPrefix = "br.com.neolog.webfilter.FilterOperation.";
            possibleValues.push(webFilterPrefix + "EQUAL");
            switch (typeName) {
                case "text":
                    possibleValues.push(webFilterPrefix + "IN");
                    possibleValues.push(webFilterPrefix + "LIKE");
                    break;
                case "number":
                    possibleValues.push(webFilterPrefix + "GREATER_THAN");
                    possibleValues.push(webFilterPrefix + "GREATER_EQUAL_THAN");
                    possibleValues.push(webFilterPrefix + "LESSER_THAN");
                    possibleValues.push(webFilterPrefix + "LESSER_EQUAL_THAN");
                    break;
                case "date":
                    possibleValues = [];
                    possibleValues.push(webFilterPrefix + "BETWEEN");
                    possibleValues.push(webFilterPrefix + "GREATER_EQUAL_THAN");
                    possibleValues.push(webFilterPrefix + "GREATER_THAN");
                    possibleValues.push(webFilterPrefix + "LESSER_EQUAL_THAN");
                    possibleValues.push(webFilterPrefix + "LESSER_THAN");
                    break;
            }
            return possibleValues;
        }

        function addExtensionValueFilter(fieldName, filterTypeName) {
            var defaultOperations = getOperationsFromType(filterTypeName);
            var filterType = {defaultOperations: defaultOperations, filterTypeName: filterTypeName};

            var filter = {
                fieldName: fieldName,
                filterType: filterType,
                operation: defaultOperations[0],
                persistentFieldName: fieldName,
                required: false,
                restFieldName: fieldName,
                value: null
            };

            selectedFilters.push(filter);
            $scope.setSelectedFilters(selectedFilters);
        }

    }]);
});
