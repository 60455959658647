define(["./remoteModule", "angular", "file-saver"], function (remoteModule, angular, fileSaver) {
    "use strict";

    var save = fileSaver.saveAs || fileSaver;

    // FIXME somente tem suporte à IE10+, devido à File API (Blob, URL.createObjectURL, Uint8Array)

    /**
     * @ngdoc service
     * @name remoteModule.downloadBinary
     * @description
     * Serviço que faz o download de arquivos binarios gerados pelo servidor.
     * @param {object} param Parâmetros como definidos em `$http`.
     * @param {string} fileName Nome do arquivo.
     * @return {promise} promesa que é resolvida quando o arquivo termina de ser buscado.
     * */
    return remoteModule.service("downloadBinary", ["$http", "remoteExceptionHandler", "$window", function ($http, remoteExceptionHandler, $window) {
        var self = function (param, fileName) {
            return $http(angular.extend(param, {
                responseType: "arraybuffer"
            })).then(function (response) {
                save(new Blob([response.data]), fileName);
            }, function (response) {
                response.data = arrayBufferToString(response.data);
                if (response.headers("content-type").indexOf("application/json") === 0) {
                    response.data = JSON.parse(response.data);
                }
                remoteExceptionHandler()(response);
            });
        };

        /**
         * @ngdoc service
         * @name remoteModule.downloadBinary#post
         * @methodOf remoteModule.downloadBinary
         * @description
         * Realiza uma requisição POST, com os dados fornecidos.
         *
         * @param {string} url Url da requisição.
         * @param {*} data Dados enviados para a requisição.
         * @param {string} fileName Nome do arquivo.
         * @param {*=} config Configurações adicionais.
         * @return {promise} Promessa que será resolvida com o download do arquivo.
         * */
        self.post = function (url, data, fileName, config) {
            return self(angular.extend(config || {}, {
                    method: "POST",
                    url: url,
                    data: data
                }),
                fileName);
        };

        /**
         * @ngdoc service
         * @name remoteModule.downloadBinary#get
         * @methodOf remoteModule.downloadBinary
         * @description
         * Realiza uma requisição GET, com os dados fornecidos.
         *
         * @param {string} url Url da requisição.
         * @param {string} fileName Nome do arquivo.
         * @param {object} params Parâmetros para ser passado para o GET.
         * @return {promise} Promessa que será resolvida com o download do arquivo.
         * */
        self.get = function (url, fileName, params) {
            return self({
                method: "GET",
                url: url,
                params: params
            }, fileName);
        };

        return self;

        function arrayBufferToString(data) {
            if (!data) {
                return "";
            }
            return String.fromCharCode.apply(null, new $window.Uint8Array(data));
        }
    }]);
});