define([
    "./nlgTableDynamicSort",
    "./nlgTableRows",
    "./nlgTableHeaders",
    "./nlgTableDynamicColumnsModule"
], function(){

});

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:tableOptions
 * @description
 * Opções da tabela que são utilizadas pelas colunas dinâmicas.
 */

/**
 * @ngdoc property
 * @name nlgTableDynamicColumns.class:tableOptions.columns
 * @propertyOf nlgTableDynamicColumns.class:tableOptions
 * @description
 * Array de {@link nlgTableDynamicColumns.class:ColumnDef ColumnDef} com configurações das colunas.
 */

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:ColumnDef
 * @description
 * Configurações de uma coluna.
 * Por padrão possui apenas dois campos: `header` e `cell`.
 * Ambos os campos podem ser uma string, ou um objeto do tipo {@link templateFactory.class:TemplateFactoryConfiguration TemplateFactoryConfiguration}.
 *
 * Quando string, será utilizado o template padrão para renderizar esta célula.
 * Quando TemplateFactoryConfiguration, as definições serão utilizadas para renderizar a célula, de acordo com o type.
 * */

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:ColumnDef.header
 * @propertyOf nlgTableDynamicColumns.class:ColumnDef
 * @description
 * Objeto do tipo {@link templateFactory.class:TemplateFactoryConfiguration TemplateFactoryConfiguration} que conterá as configurações utilizadas na
 * renderização do header.
 */

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:ColumnDef.cell
 * @propertyOf nlgTableDynamicColumns.class:ColumnDef
 * @description
 * Objeto do tipo {@link templateFactory.class:TemplateFactoryConfiguration TemplateFactoryConfiguration} que conterá as configurações utilizadas na
 * renderização das células.
 */

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:ColumnDef.width
 * @propertyOf nlgTableDynamicColumns.class:ColumnDef
 * @description
 * String responsável por definir a largura da coluna.
 */

/**
 * @ngdoc object
 * @name nlgTableDynamicColumns.class:ColumnDef.cellName
 * @propertyOf nlgTableDynamicColumns.class:ColumnDef
 * @description
 * String responsável por definir a propriedade `name` das células presentes nesta coluna.
 */