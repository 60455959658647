define(["./numberModule"], function (numberModule) {
    "use strict";

    return numberModule.filter("weight", ["$filter", function ($filter) {
        var numberFilter = $filter("number");
        return function (weight) {
            if (!weight || isNaN(weight)) {
                return "0 kg";
            }
            var finalWeight = weight;
            var unit = "kg";

            var tons = weight / 1000;
            if (tons > 1) {
                unit = "t";
                finalWeight = tons;
            }
            return numberFilter(Math.round(finalWeight * 10) / 10) + " " + unit;
        };
    }]);
});