define(["./nlgTruckAllocationModule", "../arrays/arrays", "angular", "text!./nlgTruckAllocation.html"],
    function (nlgTruckAllocationModule, arrays, angular, template) {
        "use strict";

        return nlgTruckAllocationModule.directive("nlgTruckAllocation", [function () {
            return {
                restrict: "E",
                template: template,
                scope: {},
                controller: ["$scope",
                    "autocompleteFactory",
                    "messagesModal",
                    "remoteExceptionHandler",
                    "$q",
                    "xlsService",
                    "nlgTruckAllocationService",
                    "nlgTruckAllocation",
                    "$translate",
                    function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $q, xlsService, nlgTruckAllocationService, nlgTruckAllocation, $translate) {
                        var filterPatch = [], paginationApi;
                        $scope.truckAllocationSelection = [];

                        $scope.model = {
                            isSelectedAllTruckAllocations: false
                        };

                        $scope.trucks = autocompleteFactory.lazyLoader("trucks");

                        $scope.filterServiceUrl = nlgTruckAllocation.createFilter;

                        $scope.tableOptions = {
                            records: [],
                            onRegisterPagination: function (api) {
                                paginationApi = api;
                            },
                            pagination: {
                                onPaginate: function (firstResult, maxResults) {
                                    return nlgTruckAllocationService.getAllocations( firstResult, maxResults, filterPatch )
                                        .then(function (response) {
                                            var result = response.data;
                                            result.result = result.result[0].truckAllocations;

                                            arrays.each(result.result, function (record) {
                                                record.start = new Date(record.start);
                                                record.end = new Date(record.end);

                                                record.previousPinned = record.pinned;
                                                record.originalStart = new Date(record.start);
                                                record.originalEnd = new Date(record.end);
                                                record.previousObservations = record.observations;
                                            });
                                            return result;
                                        });
                                }
                            }
                        };

                        $scope.filterTruckAllocations = function (patch) {
                            filterPatch = patch;
                            resetSelection();
                            return paginationApi.seekPage(1);
                        };

                        $scope.createAllocation = function () {
                            $scope.model.isSelectedAllTruckAllocations = false;
                            return {
                                pinned: true
                            };
                        };

                        $scope.saveAllocations = function (truckAllocation) {
                            truckAllocation.pinned = true;
                            nlgTruckAllocationService.saveAllocations(truckAllocation)
                                .then(function () {
                                    messagesModal("dialog.success", {
                                        keyBundle: "edition.truck.allocation.saveSuccessful",
                                        parameters: [$scope.truckAllocationSelection.length]
                                    })
                                        .then(function () {
                                            resetSelection();
                                            return paginationApi.seekPage(1);
                                        });
                                })
                                .catch(remoteExceptionHandler());
                        };

                        $scope.removeAllocation = function (truckAllocation, index) {
                            if (truckAllocation.truck === null) {
                                return $scope.tableOptions.records.splice(index, 1);
                            }
                            messagesModal.cancellable("dialog.warning", ["edition.truck.allocation.deleteAllocation"])
                                .then(function () {
                                    if ($scope.isTruckAllocationSelected(index)) {
                                        updateIndexes(index);
                                    }
                                    $scope.tableOptions.records.splice(index, 1);
                                    if (!truckAllocation.id) {
                                        return;
                                    }
                                    return nlgTruckAllocationService.removeAllocation(truckAllocation);
                                }).catch(function (errorResponse) {
                                if (angular.isUndefined(errorResponse) || angular.isUndefined(errorResponse.data)) {
                                    return $q.reject(errorResponse);
                                } else {
                                    return remoteExceptionHandler()(errorResponse);
                                }
                            });
                        };

                        function updateIndexes(removed) {
                            var truckAllocationSelectionUpdated = [];
                            arrays.remove($scope.truckAllocationSelection, removed);
                            arrays.each($scope.truckAllocationSelection, function (index) {
                                if (index > removed) {
                                    truckAllocationSelectionUpdated.push(index - 1);
                                } else {
                                    truckAllocationSelectionUpdated.push(index);
                                }
                            });
                            $scope.truckAllocationSelection = truckAllocationSelectionUpdated;
                        }

                        function resetSelection() {
                            arrays.clear($scope.truckAllocationSelection);
                            $scope.model.isSelectedAllTruckAllocations = false;
                        }

                        $scope.toggleAll = function () {
                            if ($scope.model.isSelectedAllTruckAllocations) {
                                var index = -1;
                                arrays.each($scope.tableOptions.records, function () {
                                    index = index + 1;
                                    $scope.truckAllocationSelection.push(index);
                                });
                            } else {
                                resetSelection();
                            }
                        };

                        $scope.toggleSelection = function (truckAllocation, index) {
                            if (arrays.contains($scope.truckAllocationSelection, index)) {
                                arrays.remove($scope.truckAllocationSelection, index);
                                $scope.model.isSelectedAllTruckAllocations = false;
                                return;
                            }
                            $scope.truckAllocationSelection.push(index);
                            if ($scope.truckAllocationSelection.length === $scope.tableOptions.records.length) {
                                $scope.model.isSelectedAllTruckAllocations = true;
                            }
                        };

                        $scope.isTruckAllocationSelected = function (index) {
                            return arrays.contains($scope.truckAllocationSelection, index);
                        };

                        $scope.isPinDisabled = function (record) {
                            record.previousPinned = record.pinned;
                            if (!record.trip) {
                                return true;
                            }
                            return record.id && !isOriginalTime(record);
                        };

                        $scope.timeChanged = function (record) {
                            if (!isOriginalTime(record)) {
                                record.pinned = true;
                            } else {
                                record.pinned = record.previousPinned;
                            }
                        };

                        function isOriginalTime(record) {
                            if (record.originalStart && record.originalEnd) {
                                return record.originalStart.getTime() === record.start.getTime() && record.originalEnd.getTime() === record.end.getTime();
                            }
                            return true;
                        }

                        var spreadsheetStyle = {
                            headers: true,
                            column: {
                                style: {
                                    Font: {
                                        Bold: "1",
                                        Color: "#FFFFFF"
                                    },
                                    Alignment: {
                                        Horizontal: "Center"
                                    },
                                    Interior: {
                                        Color: "#1CBBB2",
                                        Pattern: "Solid"
                                    }
                                }
                            }
                        };

                        $scope.getAllTruckAllocations = function () {
                            return nlgTruckAllocationService.getAllocations(0, null, filterPatch)
                                .then(function (response) {
                                    var result = response.data;
                                    result.result = result.result[0].truckAllocations;

                                    arrays.each(result.result, function (record) {
                                        record.start = new Date(record.start);
                                        record.end = new Date(record.end);
                                    });
                                    return result.result;
                                });
                        };

                        $scope.exportTruckAllocationSpreadsheet = function () {
                            $scope.getAllTruckAllocations()
                                .catch(remoteExceptionHandler())
                                .then(function (data) {
                                    return $scope.getTruckAllocationToSpreadsheetExport(data);
                                })
                                .then(function (dtos) {
                                    return xlsService($translate.instant("truckAllocation.spreadsheet"), dtos, spreadsheetStyle);
                                });
                        };

                        $scope.getTruckAllocationToSpreadsheetExport = function (data) {
                            var truckList = [];
                            angular.forEach(data, function (truckAllocation) {
                                var truckDto = {
                                    Viagem: truckAllocation.trip !== null ? truckAllocation.trip.code : "",
                                    Caminhão: truckAllocation.truck !== null ? truckAllocation.truck.name + " - " + truckAllocation.truck.sourceId : "",
                                    Transportadora: truckAllocation.carrier !== null ? truckAllocation.carrier.name : "",
                                    Veículo: truckAllocation.vehicle !== null ? truckAllocation.vehicle.name : "",
                                    Início: truckAllocation.start !== null ? new Date(truckAllocation.start).toLocaleString() : "",
                                    Fim: truckAllocation.end !== null ? new Date(truckAllocation.end).toLocaleString() : "",
                                    Observações: truckAllocation.observations !== null ? truckAllocation.observations : ""
                                };
                                truckList.push(truckDto);
                            });
                            return truckList;
                        };
                    }]
            };
        }]);
    });

